<template>
  <router-view />
  <GlobalFloatingIcon v-if="state.is_MQ_Service == true" />
</template>

<script setup>
import { useStore } from "vuex";
const { state } = useStore();
</script>

<style lang="less">
@import "./assets/css/base.css";
</style>
