<template>
  <van-nav-bar left-arrow fixed placeholder z-index="9999" :title="title" @click-left="router.back()" />
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
defineProps(['title'])
</script>

<style lang="less">
.van-nav-bar .van-icon {}
</style>
