<template>
  <div class="floating-icon" @click="handleClick">
    <img src="../assets/img/contact_green_icon.png" />

    <!-- <i
      class="Icon_icon_3a2GM icon iconfont icon-msg1 Chat_btn__out_EXYLJ"
      style="font-size: 28px"
    ></i> -->
  </div>
</template>

<script setup>
function handleClick() {
  location.href =
    "https://chatlink.wchatlink.com/widget/standalone.html?eid=f4ccc05fe9eef2d99259fc74abf3c115&language=en";
}
</script>

<style lang="less">
.floating-icon {
  position: fixed;
  bottom: 11%;
  right: 6.5%;
  width: 80px;
  height: 80px;
  // background: linear-gradient(to bottom, rgb(90, 136, 253), rgb(0, 206, 188));
  background-color: white;
  // color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s;
  border: 2px solid #10dbd5;

  img {
    width: 50px;
    height: 50px;
    color: white;
  }
}

// .floating-icon:hover {
//   // background-color: #0ebbb5;
// }
</style>
