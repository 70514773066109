import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

import router from "./router";
import "vant/lib/index.css";
import "amfe-flexible";
import MyNavBar from "./components/NavBar.vue";
import GlobalFloatingIcon from "./components/globalFloatingIcon.vue";
import NoticePop from "./components/NoticePop.vue";
import i18n from "./lang";
import {
  Button,
  NavBar,
  Field,
  Tab,
  Tabs,
  Popup,
  Swipe,
  SwipeItem,
  List,
  Badge,
  RadioGroup,
  Radio,
  Picker,
  Uploader,
  Icon,
  CountDown,
} from "vant";

const app = createApp(App);
app.use(store);
app.use(router);
app.component("NavBar", MyNavBar);
app.component("GlobalFloatingIcon", GlobalFloatingIcon);
app.component("NoticePop", NoticePop);
app
  .use(Button)
  .use(NavBar)
  .use(Field)
  .use(Tab)
  .use(Tabs)
  .use(Popup)
  .use(Swipe)
  .use(SwipeItem)
  .use(List)
  .use(i18n)
  .use(Badge)
  .use(Radio)
  .use(RadioGroup)
  .use(Picker)
  .use(Uploader)
  .use(Icon)
  .use(CountDown);
app.mount("#app");
