export default {
  shop_center_txt: "Centre commercial",
  score_txt: "Points",
  minute_txt: "minute",
  wallet_txt: "portefeuille",
  today_txt: "Aujourd'hui",
  week_txt: "cette semaine",
  month_txt: "ce mois-ci",
  year_txt: "année entière",
  main_indicators_txt: "Principaux indicateurs",
  sale_price: "prix de vente",
  expected_profit: "bénéfice attendu",
  order_count: "Quantité commandée",
  goods_click_count: "Nombre de clics sur le produit",
  supplier_txt: "Fournisseur",
  friends_help_txt: "Aide des amis",
  feedback_txt: "Commentaires",
  feedback: "Commentaires",
  online_service_txt_1: "Service client de dépôt",
  online_service_txt_2: "Consultation commerciale",
  q_and_a_txt: "Question et réponse",
  open_now_txt: "Ouvrir",
  time_txt: "heure",
  times_txt: "heure",

  wallet_total_assets: "Actifs totaux",
  balance_txt: "Solde",
  pending_settlement_txt: "règlement en attente",
  recharge_txt: "Recharger",
  withdraw_txt: "Retirer",
  fund_details_txt: "Détails du fonds",
  no_more_txt: "Plus rien",
  no_data_txt: "Aucune donnée pour l'instant",
  detail_txt: "Détails",
  num_txt: "Numéro",
  money_txt: "montant",
  remark_txt: "Remarques",
  no_remark_txt: "Aucune remarque",
  tip_txt: "astuce",
  tip_content_txt:
    "En raison de problèmes de devises actuellement souscrits par des commerçants dans divers pays",
  need_contact_service: "Besoin de contacter le service client",
  contact_service: "Contacter le service client",

  feedback_title: "Donnez votre avis",
  feedback_placeholder:
    "Pour des raisons de sécurité, veuillez ne pas inclure d'informations personnelles",
  submit_txt: "Soumettre",
  sure_txt: "OK",
  submit_suc_txt: "Soumission réussie",

  loading: "Chargement...",

  newer_action: "Activité des nouveaux arrivants",
  newer_traffic_package:
    "Package de trafic de support pour les nouveaux arrivants",
  newer_get_millions_of_products: "Obtenez des millions de produits",
  newer_get: "Reçu",
  newer_receive: "recevoir",
  newer_action_ruler: "Règles d'activité des nouveaux arrivants",
  newer_receive_suc: "Reçu avec succès",

  help_friends_help: "Aide des amis",
  help_your_friends_stores: "Aidez vos amis",
  get_store_product_heating: "Obtenir le chauffage des produits du magasin",
  help_friends_txt: "Aidez vos amis",
  help_friends_activity_rules: "Règles d'activité",
  assistance_details: "Détails de l'assistance",
  team_list: "Liste des équipes",
  help_friend_name: "Nom de l'ami de l'aide",
  help_friend_invite_code: "Code d'invitation d'un ami d'aide",
  mine_invite_code: "Mon code d'invitation",
  plz_input: "Veuillez entrer",
  plz_input_friend_name: "Veuillez entrer le nom de votre ami pour vous aider",
  help_suc: "Aide à la réussite",

  online_service: "Service client en ligne",
  online_24_hour_service: "Assistance en ligne 24 heures sur 24",
  customer_service: "service client",
  copy_and_add_id: "Copier et ajouter l'ID de LIGNE",
  plz_input_question_detail: "Veuillez saisir les détails de votre question",
  harry_reply_your: "Nous vous répondrons dans les plus brefs délais",
  click_copy: "Cliquez pour copier",
  copy_suc: "Copie réussie",
  copy_fail: "La copie a échoué",

  overall: "tous",
  cooperate: "coopérer",
  cancel: "annuler",
  supplier_num: "Numéro du fournisseur",
  no_use_supplier: "Aucun fournisseur disponible",
  here_add_supplier: "Ajouter un nouveau fournisseur ici",
  add_supplier: "Ajouter un fournisseur",
  plz_input_supplier_num: "Veuillez saisir le numéro du fournisseur",
  add_suc: "Ajouter avec succès",
  remove_suc: "Supprimé avec succès",

  new_products_launched: "Nouveaux produits lancés",
  profit_txt: "Bénéfice",
  has_been_added: "Déjà en rayon",
  on_display: "Peut être mis en étagère",
  coming_soon_txt: "Disponible maintenant",
  sold_txt: "Vendu",
  removed_txt: "Supprimé",

  order_detail_txt: "Détails de la commande",
  waiting_express_delivery: "En attente de livraison express",
  customer_info: "Informations client",
  order_good: "Commander des marchandises",
  order_num: "Numéro de commande",
  order_date: "date de commande",
  order_content: "Résumé de la commande",
  purchase_price_txt: "Prix d'achat",
  income_txt: "Revenu",

  order_txt: "Commande",
  ending_payment: "paiement en attente",
  ready_for_shipment: "À expédier",
  shipped_txt: "expédié",
  paid_txt: "Livré",
  good_num: "numéro d'article",
  pay_and_ship: "Paiement et expédition",
  pay_suc: "Paiement réussi",
  no_order: "Pas encore de commande",
  no_order_tips: "Vous pouvez suivre vos commandes et retours ici",
  suer_order: "Confirmer le paiement",
  pay_way: "mode de paiement",
  wallet_pay: "paiement par portefeuille",
  sure_pay: "Confirmer le paiement",
  paying_txt: "Payer...",

  manage_showcase: "Gérer la vitrine",
  add_new_good: "Ajouter un nouveau produit",
  heating_txt: "Chauffage",
  promotion: "promotion",
  promotion_tip: "Conseil promotionnel",
  promotion_tip_txt:
    "Vos points actuels sont inférieurs à 200 et vous ne pouvez pas payer vos produits",
  copy_link: "Copier le lien",
  scan_qr_code: "Scanner le code QR",

  about_good: "À propos du produit",
  specification: "spécification",
  product_overview: "Présentation du produit",

  top_tips_1: "Transformez la boutique Douyin en ligne",
  top_tips_2: "Actuellement, ne prend en charge que les invitations internes",

  certification_activation_code: "code d'invitation",
  plz_write_invite_code: "Veuillez saisir le code d'invitation",
  active_user_email: "e-mail",
  plz_input_active_user_email: "Veuillez entrer votre email",
  active_user_email_code: "Code de vérification de l'e-mail",
  plz_input_active_user_email_code:
    "Veuillez saisir le code de vérification de l'e-mail",
  active_get_email_code_txt: "Obtenir le code de vérification",
  active_user_name: "nom",
  plz_input_active_user_name: "Veuillez entrer votre nom",
  active_user_id: "numéro de carte d'identité/passeport",
  plz_input_active_user_id:
    "Veuillez entrer votre numéro de carte d'identité/passeport",
  upload_active_user_id: "Téléchargement du certificat",
  plz_upload_user_id_img:
    "Veuillez télécharger la photo du recto de votre carte d'identité/page de passeport",

  promotion_01: "Promotion",
  promotion_02: "Montant de la promotion",
  promotion_03: "Veuillez saisir le montant de la promotion",
  promotion_04: "Confirmer la promotion",
  promotion_05: "Promotion réussie",

  bao_txt: "Taux d'exposition",
  bal_no: "Solde insuffisant",

  wallet_type_01: "Recharge manuelle du système",
  wallet_type_02: "Retrait manuel",
  wallet_type_03: "Paiement",
  wallet_type_04: "Déduction du système",
  wallet_type_05: "Revenu bénéficiaire",
  wallet_type_06: "Déduction de paiement",
  wallet_type_07: "Consommation de distribution",
  wallet_type_08: "Revenus promotionnels",
  wallet_type_09: "Boostez les récompenses",
  wallet_type_10: "Inscrivez-vous gratuitement",
  wallet_type_11: "Récompense de chiffre d'affaires",
  wallet_type_12: "Retrait rejeté",

  all_orders: "Toutes les commandes",
  mine_orders: "Mes commandes",
  mine_services: "Mes services",
  _tzz: "Sauter...",

  info_01: "Nom",
  info_02: "E-mail",
  info_03: "Coordonnées",
  info_04: "Genre",
  info_05: "Confidentiel",
  info_06: "Homme",
  info_07: "Femme",
  info_08: "Date de naissance(yyyy-MM-dd)",
  info_09: "Pays",
  info_10: "Ville",
  info_11: "Numéro de carte",
  info_12: "Type de carte",
  info_13: "Émetteur de la carte",
  info_14: "Date d`expiration(yyyy-MM-dd)",
  info_15: "Code CVV",
  info_16: "Mot de passe de sécurité",
  info_17: "Répéter le mot de passe de sécurité",
  info_18: "Veuillez saisir à nouveau le mot de passe de sécurité",
  info_19: "Les deux mots de passe de sécurité sont incohérents",
  info_20: "Carte d'identité/passeport",
  info_21: "Sélectionnez le type d'image à télécharger",
  info_22: "Carte d'identité (recto et verso)",
  info_23: "Passeport",
  info_24: "Permis de conduire",
  info_25: "Permis de séjour",
  info_26: "Certificat Sanitaire",
  info_27: "Veuillez télécharger une photo d'identité",
  info_28:
    "Veuillez contacter le service client pour vérifier les informations bancaires pour le paiement.",

  wl_withdraw_money: "Montant du retrait",
  wl_withdraw_code_201:
    "Les enregistrements de retrait sont en cours d`examen, veuillez ne pas les soumettre à nouveau",
  wl_withdraw_code_202:
    "Les informations de la carte bancaire sont incorrectes",
  wl_withdraw_code_400: "Exception d`enregistrement de retrait",
  wl_withdraw_code_204: "Mot de passe de sécurité incorrect",
  wl_withdraw_code_205: "Le solde de marge ne peut pas être retiré",
  wl_withdraw_code_207:
    "Le nombre d`étoiles de l`utilisateur actuel ne peut pas être retiré, veuillez augmenter le nombre d`étoiles",
  wl_withdraw_code_208:
    "Le nombre d`étoiles de l`utilisateur actuel a atteint le nombre maximum de retraits aujourd`hui",

  wl_withdraw_order_01: "Tous",
  wl_withdraw_order_02: "Non révisé",
  wl_withdraw_order_03: "Audité",
  wl_withdraw_order_04: "Échec du retrait",
  wl_withdraw_order_05: "Numéro",
  wl_withdraw_order_06: "Montant du retrait",
  wl_withdraw_order_07: "Méthode de retrait",
  wl_withdraw_order_08: "Informations de retrait",
  wl_withdraw_order_09: "À réviser",
  wl_withdraw_order_10: "Approuvé",
  wl_withdraw_order_11: "Terminé",
  wl_withdraw_order_12: "Annulé",
  wl_withdraw_order_13: "gelé",

  hl_mine_invite_code: "Mon code d`invitation",
  hl_help_friend_invite_code: "Code d`invitation d`un ami d`aide",
  hl_certification_activation_code: "code d`invitation",
  hl_plz_write_invite_code: "Veuillez saisir le code d`invitation",
  hl_help_code_0: "Le code d`invitation ne peut pas être vide",
  hl_help_code_201: "L`utilisateur a lié le code d`invitation",
  hl_help_code_202: "Le code d`invitation est erroné",
  hl_help_code_203: "Le code d`invitation ne peut pas être lié à vous-même",
  gl_visits: "Visites",

  _in_sp: "Classement des produits",
  _r_k01: "Classement",
  _r_k02: "Marchandise",
  _r_k03: "chaleur",

  _zw_ph: "Aucune information sur le classement des produits pour l’instant ! ",
  _zf_201: "Statut de la commande anormal",
  _zf_202: "Solde d’actif insuffisant",
  _zf_203: "Le paiement a échoué",

  _withdraw_order_txt: "Retirer la commande",

  shop_num: "Numéro de magasin",
  plz_input_shop_num: "Veuillez saisir le numéro du magasin",
  shop_num_error: "Le numéro de magasin est incorrect",
  supplier_num_error: "Le numéro de fournisseur est incorrect",
  supplier_num_has_bound: "Le numéro de fournisseur a été lié",

  sc_day_sale: "Ventes quotidiennes",
  sc_week_sale: "ventes hebdomadaires",
  hl_mine_shop: "Ma boutique",
  hl_shop_name: "Nom de la boutique",
  hl_input_shop_name: "Veuillez saisir le nom de la boutique",
  hl_add_shop_name: "Ajouter le nom de la boutique",
  hl_update_shop_name: "Modifier le nom de la boutique",
  hl_update_suc: "Modification réussie",

  hl_rabate_01: "Commission de niveau inférieur",
  hl_rabate_02: "Enregistrement de la remise",
  hl_rabate_03: "Taux de remise",
  hl_rabate_04: "Montant total de la commission",
  hl_rabate_05: "Ventes totales",
  hl_rabate_06: "Montant des ventes",
  hl_rabate_07: "Montant de la remise",
  hl_rabate_08: "Heure d`inscription",
  hl_rabate_09: "Assistance niveau 1",
  hl_rabate_10: "Assistance niveau 2",
  hl_rabate_11: "Assistance niveau 3",

  dd_order_01: "Annuler la commande",
  dd_order_02: "Retour",
  dd_order_03: "De retour",
  dd_order_04: "Revenu",

  _t_p_qr: "Confirmer",
  _t_p_txt01:
    "S’il faut annuler la commande ? L’annulation de la commande est irrévocable et introuvable ! ! ! ",
  _t_p_txt02: "Veuillez confirmer si cette commande doit être retournée ! ",
  _t_p_txt03: "Annulation réussie",
  _t_p_txt04: "La demande de retour a été soumise",

  showcase_01: "Passer une commande",
  showcase_02: "Commande passée avec succès",
  showcase_03: "Veuillez entrer votre identifiant d`ami",

  goods_price: "Prix",

  u_pwd_tips:
    "Veuillez contacter le service client pour modifier le mot de passe de sécurité",

  g_to_be_purchased: "à acheter",
  g_purchasing_and_shipping: "Achat et expédition",
  g_suer_purchase_order: "Confirmer l`achat",

  hl_star_rating_text_1: "Marchand une étoile",
  hl_star_rating_text_2: "Marchand deux étoiles",
  hl_star_rating_text_3: "Marchand trois étoiles",
  hl_star_rating_text_4: "Marchand quatre étoiles",
  hl_star_rating_text_5: "Marchand cinq étoiles",

  hl_auth_tips:
    "L`authentification par nom réel a été soumise et est en cours de révision...",

  ml_01: "Non lu",
  ml_02: "Lire",
  ml_03: "Contenu du message...",
  ml_04: "Envoyer",
  ml_05: "Téléchargement...",

  gl_sale_price: "prix de vente",

  wd_fee: "Frais de traitement",
  wd_receipt: "Arrivée réelle",
  wd_receipt_way: "Sélectionnez un mode de paiement existant",
  wd_bank_info: "Informations bancaires",
  wd_bank_info_tip:
    "Format : Nom-Nom de la banque-Compte-Numéro de carte bancaire",
  wd_success: "Retrait réussi",
  wd_success_tip:
    "Votre demande de retrait a été acceptée et devrait arriver sur votre compte dans les 24 heures.",

  g_removed: "Supprimé",
  g_sale_ing: "En solde",

  sd_01: "Produits recommandés",
  sd_02: "Plus",
  sd_03: "Magasin recommandé",
  sd_04: "Produit actuel",
  sd_05: "Un",
  sd_06: "Voir la boutique",
  sd_07: "Produits du magasin",
  sd_08: "Magasin d`activités",
  sd_09: "Magasin illégal",

  mc_01: "Signé pour",
  mc_02: "Payé",
  mc_03: "Heure de signature",
  mc_04: "Délai de paiement",
  mc_05: "Numéro de commande",
  mc_06: "Signature",
  mc_07: "Acheteur",
  mc_08: "Adresse",
  mc_09: "Région",
  mc_10: "Contacter l`acheteur",

  lgi_01: "Informations logistiques",
  lgi_02: "Correspondre à l'entrepôt de marchandises international",
  lgi_03: "Livraison express en cours",
  lgi_04: "En transit",
  lgi_05: "Livraison",

  np_01: "Avertissement de risque",
  np_02: "Annonce du système",
  np_03: "J`ai lu et compris",
  np_04: "Actualités",

  sl_01: "Liste en un clic",

  w_pwd_info_01: "Mot de passe de retrait",
  w_pwd_info_02: "Répéter le mot de passe de retrait",
  w_pwd_info_03: "Veuillez saisir à nouveau le mot de passe de retrait",
  w_pwd_info_04: "Les deux mots de passe de retrait sont incohérents",
  w_pwd_info_05: "Mauvais mot de passe de retrait",
  w_pwd_info_06:
    "Veuillez contacter le service client pour modifier le mot de passe de retrait",

  l_g_01: "E-mail",
  l_g_02: "Veuillez saisir votre adresse email",
  l_g_03: "Code de vérification",
  l_g_04: "Veuillez saisir le code de vérification",
  l_g_05: "Envoyer",
  l_g_06: "Mot de passe de connexion",
  l_g_07: "Veuillez saisir votre mot de passe de connexion",
  l_g_08: "Répéter le mot de passe de connexion",
  l_g_09: "Veuillez saisir à nouveau votre mot de passe de connexion",
  l_g_10: "S`inscrire",
  l_g_11: "Connexion",
  l_g_12: "Vous avez déjà un compte, connectez-vous",
  l_g_13: "Aller à l`inscription",
  l_g_14: "Déconnexion",
  l_g_15: "Confirmer pour se déconnecter ?",
  l_g_16: "Langue",
  l_g_17: "Les deux mots de passe de connexion sont incohérents",
  l_g_18:
    "Les informations utilisateur n`existent pas, veuillez vérifier si l`ID est saisi correctement~",
  l_g_19: "Nom d`utilisateur",
  l_g_20: "Veuillez saisir votre nom d`utilisateur",
  l_g_21: "Connectez-vous et copiez",

  h_c_01: "Bénéfice de la commande",
  h_c_02: "Payé",
  h_c_03: "À expédier",
  h_c_04: "Confirmé",
  h_c_05: "Ventes",
  h_c_06: "Code d`invitation à l`ouverture d`un magasin",
  h_c_07: "Veuillez saisir le code d`invitation au magasin",
  h_c_08: "Inviter des amis",

  g_c_01: "Ma collection",
  g_c_02: "Collecte réussie",
};
